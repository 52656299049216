import React from 'react';

// Component to add canonical link tags to help with SEO
const CanonicalLink = ({ path = '' }) => {
  const baseUrl = 'https://ghentevents.co';
  const canonicalUrl = `${baseUrl}${path}`;

  return (
    <link rel="canonical" href={canonicalUrl} />
  );
};

export default CanonicalLink; 